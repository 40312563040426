import { FC, useMemo } from 'react';
import { ApiResponse } from '../../models/ApiResponse';
import { IndexListItem } from '../../models/Document';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { useTranslation } from 'react-i18next';
import IndexRow from './IndexRow';
import DocumentSpaceIcon from '../shared/icon/DocumentSpaceIcon';
import Loader from '../shared/Loader';

type IndexListProps = {
  indexesPaged: ApiResponse<IndexListItem[]>;
  onLoadMore?: (pageNumber: number) => void;
  isLoading?: boolean;
  onDownloadIndex: (id: string) => void;
};

const IndexList: FC<IndexListProps> = (props) => {
  const { indexesPaged, onLoadMore, isLoading = false, onDownloadIndex } = props;
  const { t } = useTranslation(['documents', 'common']);
  const currentPage = indexesPaged.pageNumber || 0;
  const totalPages = indexesPaged.totalPages || 0;
  const [lastElementRef] = useInfiniteScroll(currentPage < totalPages ? () => onLoadMore && onLoadMore(currentPage + 1) : null, isLoading);
  const indexes = useMemo(() => indexesPaged.data || [], [indexesPaged.data]);

  return (
    <div>
      {indexes.length > 0 && (
        <div className="flex gap-x-4 px-4 pb-4 font-medium text-black">
          <div className="flex w-3/5 gap-x-4">
            <div className="w-1/6">{t('documents:index-modal.heading.version')}</div>
            <div className="w-2/6">{t('documents:index-modal.heading.status')}</div>
            <div className="w-3/6">{t('documents:index-modal.heading.modified')}</div>
          </div>
          <div className="w-2/5">{t('documents:index-modal.heading.changes')}</div>
          <div className="w-5"></div>
        </div>
      )}
      {indexes.map((indexItem, i) => {
        const isLast = indexes.length === i + 1;
        return (
          <div key={indexItem.id} ref={isLast ? lastElementRef : undefined}>
            <IndexRow data-cy={`index-list-item-${i}`} indexItem={indexItem} onDownload={onDownloadIndex} />
          </div>
        );
      })}
      {isLoading && (
        <div className="flex flex-col items-center py-6">
          <Loader size={16} centered={false} />
        </div>
      )}
      {!isLoading && indexes.length === 0 && (
        <div data-cy="documents-empty" className="mx-auto flex h-full w-3/4 flex-col items-center justify-center p-16 text-center">
          <DocumentSpaceIcon className="bg-primary-1 text-primary-1 h-16 w-16 rounded-full bg-opacity-10 p-4" />
          <div className="text-dpm-20 text-color-3 mt-8">{t(`documents:list.no-indexes.heading`)}</div>
          <p className="text-color-3 pb-4">{t(`documents:list.no-indexes.description`)}</p>
        </div>
      )}
    </div>
  );
};

export default IndexList;
