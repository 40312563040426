import throttle from 'lodash.throttle';
import { useMemo, useRef, useState, ComponentProps, useCallback, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import { roleOptions } from '../../components/ownership/RoleOptions';
import Button, { ButtonSize, ButtonType } from '../../components/shared/form-control/Button';
import { InputStyle } from '../../components/shared/form-control/Input';
import { SearchInput } from '../../components/shared/form-control/SearchInput';
import SkeletonLoader from '../../components/shared/skeleton-loader/SkeletonLoader';
import FilterTag, { FilterSelectMode } from '../../components/shared/tags/FilterTag';
import { ActivitiesContext } from '../../contexts/ActivitiesContext';
import { DataJobSource, SelectItemContextProvider } from '../../contexts/select-items/SelectItemsContext';
import { EventSystem } from '../../events/EventSystem';
import { useCurrentRoute } from '../../hooks/useCurrentRoute';
import useDebounce from '../../hooks/useDebounce';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import usePermissions from '../../hooks/permissions/usePermissions';
import { useStoredFilters } from '../../hooks/useStoredFilters';
import { ApiResponse } from '../../models/ApiResponse';
import { ClientFormStatus, documentStatusKeys } from '../../models/ClientFormStatus';
import { ClientFormUserRoleKeys, ClientFormUserRoleValues } from '../../models/ClientFormUserRoles';
import { FormType } from '../../models/FormTypes';
import { Roles } from '../../models/Role';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import ClientFormService from '../../services/ClientFormService';
import { nextTick } from '../../utils/ReactUtils';
import { Option } from '../../components/Option';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import DocumentList from '../../components/documents/DocumentList';
import TabButton from '../../components/shared/tab-strip/TabButton';
import LanguageUtils from '../../utils/LanguageUtils';
import { ModuleType } from '../../models/Module';
import CogIcon from '../../components/shared/icon/CogIcon';
import ContextMenu from '../../components/shared/ContextMenu';
import ClientTemplateModuleService from '../../services/ClientTemplateModuleService';
import { ModalContext } from '../../contexts/ModalContext';
import { ClientModuleTemplateDefault } from '../../models/ClientModuleDefaults';
import ManageClassesWizard, { ManageClassesWizardStep, manageClassesWizardSteps } from '../../components/documents/manage-classes/ManageClassWizard';
import CreateDocumentWizard, {
  CreateDocumentWizardStep,
  createDocumentWizardSteps,
} from '../../components/documents/create-document/CreateDocumentWizard';
import ModuleService from '../../services/ModuleService';
import { DataJobSourceType } from '../../models/DataImport';
import { DocumentListItem, IndexListItem } from '../../models/Document';
import StandardModal from '../../components/shared/modal/variants/StandardModal';
import IndexList from '../../components/documents/IndexList';
import DownloadIcon from '../../components/shared/icon/DownloadIcon';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import DateUtils from '../../utils/DateUtils';
import { PeriodicReviewStatus } from '../../models/PeriodicReview';
import useFetchClientUsers from '../../hooks/useFetchClientUsers';
import { DistributionResponse, DistributionStatusFilter, DistributionStatusFilterKeys } from '../../models/Distribution';
import { useFeatureFlags } from '../../contexts/FeatureFlagContext';

const ARCHIVED_VALUE = 99;
const FORM_STATUS_PREFIX = 'form-status-';
const PERIODIC_STATUS_PREFIX = 'periodic-status-';
const DISTRIBUTION_STATUS_PREFIX = 'distribution-status-';

const Documents: FC = () => {
  const { featureFlags } = useFeatureFlags();
  const { t, i18n } = useTranslation(['documents', 'module', 'common', 'organisation', 'data-import-export', 'distribution']);
  const { search: searchLocationString, hash: hashLocation } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(searchLocationString), [searchLocationString]);
  const currentClient = useRecoilValue(currentClientAtom);
  const { id: currentPageType, match: currentRouteInfo } = useCurrentRoute();
  const modulePageId = useMemo(() => currentRouteInfo?.params.moduleId || '', [currentRouteInfo?.params.moduleId]);
  const currentPageId = useMemo(
    () => `${currentClient?.id}-${currentPageType}${modulePageId ? '-' + modulePageId : ''}`,
    [currentClient?.id, currentPageType, modulePageId],
  );
  const currentUser = useRecoilValue(currentUserAtom);
  const pageContentRef = useRef<HTMLDivElement>(null);
  const indexesContentRef = useRef<HTMLDivElement>(null);
  const [documents, setDocuments] = useState<ApiResponse<DocumentListItem[]> | null>(null);
  const [indexes, setIndexes] = useState<ApiResponse<IndexListItem[]> | null>(null);
  const [currentIndexPage, setCurrentIndexPage] = useState(1);
  const [indexesLoading, setIndexesLoading] = useState(true);
  const [clientUsersOptions, setClientUsersOptions] = useState<Option<string, boolean>[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isActivitiesLoading, setIsActivitiesLoading] = useState(true);
  const [activitySearchPhrase, setActivitySearchPhrase] = useLocalStorageState(`${currentPageId}-search`, '');
  const [sortBy, setSortBy] = useState('+subtitle,+modifiedUtc,+dueDateUtc');
  const debouncedSearchTerm = useDebounce(activitySearchPhrase, 500);
  const hasPermission = usePermissions();
  const [selectedClass, setSelectedClass] = useState('');
  const [clientModuleTemplateDefaults, setClientModuleTemplateDefaults] = useState<ClientModuleTemplateDefault[]>([]);
  const [showManageWizard, setShowManageWizard] = useState(false);
  const [activeManageWizardStep, setActiveManageWizardStep] = useState<ManageClassesWizardStep>();

  const [showNewDocumentWizard, setShowNewDocumentWizard] = useState(false);
  const [activeNewDocumentWizardStep, setActiveNewDocumentWizardStep] = useState<CreateDocumentWizardStep>();

  const [showIndexModal, setShowIndexModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const hashValue = hashLocation?.replace('#', '');
    if (hashValue) {
      setSelectedClass(hashValue);
    } else {
      setSelectedClass('');
    }
  }, [hashLocation]);

  const defaultStatusFilters = useMemo<ComponentProps<typeof FilterTag>['options']>(() => {
    const documentStatusFilters = [
      { id: 'activity_status_heading', text: t('common:document-status.heading'), value: false, disabled: true, hasDivider: true },
      {
        id: `${FORM_STATUS_PREFIX}${ClientFormStatus.InProgress}`,
        text: t(documentStatusKeys[ClientFormStatus.InProgress]),
        value: false,
      },
      {
        id: `${FORM_STATUS_PREFIX}${ClientFormStatus.SubmittedForValidation}`,
        text: t(documentStatusKeys[ClientFormStatus.SubmittedForValidation]),
        value: false,
      },
      {
        id: `${FORM_STATUS_PREFIX}${ClientFormStatus.SubmittedForApproval}`,
        text: t(documentStatusKeys[ClientFormStatus.SubmittedForApproval]),
        value: false,
      },
      {
        id: `${FORM_STATUS_PREFIX}${ClientFormStatus.Completed}`,
        text: t(documentStatusKeys[ClientFormStatus.Completed]),
        value: false,
      },
      {
        id: `${FORM_STATUS_PREFIX}${ARCHIVED_VALUE}`,
        text: t('common:list.filter.archived-documents'),
        value: false,
      },
    ];

    const periodicReviewFilters = [
      { id: 'periodic_status_heading', text: t('common:periodic-review-status.heading'), value: false, disabled: true, hasDivider: true },
      {
        id: `${PERIODIC_STATUS_PREFIX}${PeriodicReviewStatus.UpcomingReview}`,
        text: t('common:periodic-review-status.upcoming'),
        value: false,
      },
      {
        id: `${PERIODIC_STATUS_PREFIX}${PeriodicReviewStatus.UnderReview}`,
        text: t('common:periodic-review-status.under-review'),
        value: false,
      },
      {
        id: `${PERIODIC_STATUS_PREFIX}${PeriodicReviewStatus.ReviewCompleted}`,
        text: t('common:periodic-review-status.completed'),
        value: false,
      },
      {
        id: `${PERIODIC_STATUS_PREFIX}${PeriodicReviewStatus.ReviewOverdue}`,
        text: t('common:periodic-review-status.overdue'),
        value: false,
        hasDivider: true,
      },
    ];

    const distributionFilters = featureFlags.distributionList
      ? [
          { id: 'distribution_status_heading', text: t('distribution:filters.heading'), value: false, disabled: true, hasDivider: true },
          {
            id: `${DISTRIBUTION_STATUS_PREFIX}${DistributionStatusFilter.Active}`,
            text: t(DistributionStatusFilterKeys[DistributionStatusFilter.Active]),
            value: false,
          },
          {
            id: `${DISTRIBUTION_STATUS_PREFIX}${DistributionStatusFilter.InActive}`,
            text: t(DistributionStatusFilterKeys[DistributionStatusFilter.InActive]),
            value: false,
          },
          {
            id: `${DISTRIBUTION_STATUS_PREFIX}${DistributionStatusFilter.ActionNeededByCurrentUser}`,
            text: t(DistributionStatusFilterKeys[DistributionStatusFilter.ActionNeededByCurrentUser]),
            value: false,
          },
          {
            id: `${DISTRIBUTION_STATUS_PREFIX}${DistributionStatusFilter.Completed}`,
            text: t(DistributionStatusFilterKeys[DistributionStatusFilter.Completed]),
            value: false,
          },
        ]
      : [];

    return [...documentStatusFilters, ...periodicReviewFilters, ...distributionFilters];
  }, [featureFlags.distributionList, t]);

  const defaultRoleFilters = useMemo(
    () =>
      roleOptions.map((x) => ({
        id: x.id,
        text: t(ClientFormUserRoleKeys[x.value as ClientFormUserRoleValues]),
        value: false,
      })),
    [t],
  );

  const [clientUsersFilterDefaults, setClientUsersFilterDefaults] = useState<Option<string, boolean>[]>([]);

  const filterDefaults = useMemo(
    () => ({
      statusFilters: defaultStatusFilters,
      roleFilters: defaultRoleFilters,
      clientUsersFilters: clientUsersFilterDefaults,
    }),
    [clientUsersFilterDefaults, defaultRoleFilters, defaultStatusFilters],
  );

  const [
    { statusFilters, roleFilters, clientUsersFilters },
    { setStatusFilters, setRoleFilters, setClientUsersFilters },
    { hasFiltersApplied, clearFilters },
  ] = useStoredFilters(`${currentPageId}`, filterDefaults);

  const clearAllFilters = useCallback(() => {
    clearFilters();
  }, [clearFilters]);

  const [filterArchived, setFilterArchived] = useState(!!statusFilters.find((x) => x.id === `${FORM_STATUS_PREFIX}${ARCHIVED_VALUE}`)?.value);

  const applyStatusFilter = useCallback(
    (filters: Option<string, boolean>[]) => {
      setStatusFilters(filters);
      setFilterArchived(!!filters.find((x) => x.id === `${FORM_STATUS_PREFIX}${ARCHIVED_VALUE}`)?.value);
    },
    [setStatusFilters],
  );

  const applyUsersFilter = useCallback(
    (filters: Option<string, boolean>[]) => {
      setClientUsersFilters(filters);
    },
    [setClientUsersFilters],
  );

  const onSortBy = (expression: string) => {
    setDocuments(null);
    setCurrentPage(1);
    setSortBy(expression);
  };

  const formStatusFilters = useMemo(() => {
    return statusFilters
      .filter((x) => `${x.id}`.startsWith(FORM_STATUS_PREFIX))
      .map((x) => ({ ...x, id: parseInt(`${x.id}`.substring(FORM_STATUS_PREFIX.length), 10) }));
  }, [statusFilters]);

  const periodicStatusFilters = useMemo(() => {
    return statusFilters
      .filter((x) => `${x.id}`.startsWith(PERIODIC_STATUS_PREFIX))
      .map((x) => ({ ...x, id: parseInt(`${x.id}`.substring(PERIODIC_STATUS_PREFIX.length), 10) }));
  }, [statusFilters]);

  const distributionStatusFilters = useMemo(() => {
    return statusFilters
      .filter((x) => `${x.id}`.startsWith(DISTRIBUTION_STATUS_PREFIX))
      .map((x) => ({ ...x, id: parseInt(`${x.id}`.substring(DISTRIBUTION_STATUS_PREFIX.length), 10) }));
  }, [statusFilters]);

  const selectedClientModuleDefault = useMemo(() => {
    return clientModuleTemplateDefaults.find((x) => x.templateModule.id === selectedClass);
  }, [clientModuleTemplateDefaults, selectedClass]);

  const formsFilter = useMemo(() => {
    if (!currentUser) return;
    if (clientUsersOptions.length === 0) return;

    const filteredFormStatusses = formStatusFilters.filter((filter) => filter.value && filter.id !== ARCHIVED_VALUE);
    return {
      clientModuleId: selectedClientModuleDefault?.clientModuleId,
      title: debouncedSearchTerm,
      isArchived: filterArchived,
      types: [FormType.Document],
      roles: roleFilters.filter((filter) => filter.value).map((filter) => parseInt(filter.id, 10) as ClientFormUserRoleValues),
      users: !hasPermission(Roles.TeamMember)
        ? [currentUser.id || '']
        : clientUsersFilters.filter((filter) => filter.value).map((filter) => filter.id),
      formStatus:
        filteredFormStatusses.length === 0
          ? formStatusFilters.map((filter) => filter.id).filter((x) => x !== ARCHIVED_VALUE)
          : filteredFormStatusses.map((filter) => filter.id),
      periodicReviewStatuses: periodicStatusFilters.filter((filter) => filter.value).map((filter) => filter.id),
      distributionStatus: distributionStatusFilters.filter((filter) => filter.value).map((filter) => filter.id),
      pageSize: 15,
      sortBy: sortBy,
      importId: searchParams.get('importId'),
    };
  }, [
    currentUser,
    clientUsersOptions.length,
    formStatusFilters,
    selectedClientModuleDefault?.clientModuleId,
    debouncedSearchTerm,
    filterArchived,
    roleFilters,
    hasPermission,
    clientUsersFilters,
    periodicStatusFilters,
    distributionStatusFilters,
    sortBy,
    searchParams,
  ]);

  useEffect(
    function resetFilters() {
      setCurrentPage(1);
      setDocuments(null);
      // Reset when any of these filters change
    },
    [
      formsFilter?.title,
      formsFilter?.types,
      formsFilter?.roles,
      formsFilter?.users,
      formsFilter?.formStatus,
      formsFilter?.sortBy,
      formsFilter?.isArchived,
    ],
  );

  const requestAbortController = useRef<AbortController>();

  const filterForms = useCallback(() => {
    if (!formsFilter || !currentClient) return;

    const scrollTop = pageContentRef.current?.scrollTop || 0;
    setIsActivitiesLoading(true);
    const service = ClientFormService.getFormsPaged.bind(ClientFormService);
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }
    requestAbortController.current = new AbortController();
    service(currentClient.id, { ...formsFilter, pageNumber: currentPage }, { signal: requestAbortController.current.signal }).then((res) => {
      setDocuments((prev) => {
        if (prev?.data && currentPage > 1) {
          return { ...res, data: [...prev.data, ...res.data] } as ApiResponse<DocumentListItem[]> | null;
        }
        return { ...res } as ApiResponse<DocumentListItem[]> | null;
      });
      setIsActivitiesLoading(false);

      nextTick(() => {
        pageContentRef.current?.scrollTo({ top: scrollTop });
      });
    });
  }, [currentClient, currentPage, formsFilter]);

  const ownersFilterSearch = useMemo(() => {
    return throttle((searchTerm: string) => {
      setClientUsersFilterDefaults((prev) => {
        // merge the previous filtered result with source
        const mergedUsers = [...prev, ...clientUsersOptions];

        // remove duplicates
        const set = new Set();
        const unionArray = mergedUsers.filter((user) => {
          if (!set.has(user.id)) {
            set.add(user.id);
            return true;
          }
          return false;
        }, set);

        const result = unionArray
          .filter((user) => {
            const value = searchTerm.toLowerCase();
            return user.text.toLowerCase().search(value) > -1;
          })
          .sort((a, b) => (a.text > b.text ? 1 : -1));

        return result;
      });
    }, 500);
  }, [clientUsersOptions]);

  const { data: users = [], refetch } = useFetchClientUsers();

  useEffect(() => {
    const usersOptions = users
      .sort((a, b) => (`${a.firstName}` > `${b.firstName}` ? 1 : `${b.lastName}` > `${a.lastName}` ? -1 : 0))
      .map((user) => ({
        id: `${user.id}`,
        text: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : `${user.email}`,
        value: hasPermission(Roles.TeamMember) ? false : currentUser?.id === user.id,
      }));
    setClientUsersOptions(usersOptions);
    setClientUsersFilterDefaults([...usersOptions]);
  }, [currentUser?.id, hasPermission, users]);

  useEffect(() => {
    filterForms();
  }, [filterForms]);

  useEffect(function typeChange() {
    setCurrentPage(1);
    setDocuments(null);
  }, []);

  useEffect(function langaugeChanged() {
    const handler = () => setDocuments(null);

    EventSystem.listen('language-changed', handler);
    return () => {
      EventSystem.stopListening('language-changed', handler);
    };
  }, []);

  const getClasses = useCallback(() => {
    if (currentClient) {
      const clientTemplateModuleService = new ClientTemplateModuleService(currentClient?.id);
      clientTemplateModuleService.getTemplatesConfigurations(ModuleType.Document).then((templateConfigs) => {
        ModuleService.getModules().then((res) => {
          res.data.forEach((clientModule) => {
            const moduleTemplate = templateConfigs.data.find((x) => x.templateModule.id === clientModule.templateId);
            if (moduleTemplate) {
              moduleTemplate.clientModuleId = clientModule.id;
              moduleTemplate.clientModuleName = LanguageUtils.getTranslation('name', clientModule.translations, i18n.language);
            }
          });
          setClientModuleTemplateDefaults(templateConfigs.data.sort((a, b) => a.templateModule.sortOrder - b.templateModule.sortOrder));
          if (templateConfigs.data.length === 0) {
            setActiveManageWizardStep(manageClassesWizardSteps[0]);
            setShowManageWizard(true);
          }
        });
      });
    }
  }, [currentClient, i18n.language]);

  useEffect(() => {
    getClasses();
  }, [getClasses]);

  const onClassCreated = useCallback(() => {
    getClasses();
  }, [getClasses]);

  const onClassUpdated = useCallback((value: ClientModuleTemplateDefault) => {
    setClientModuleTemplateDefaults((prev) => {
      return prev.map((item) => {
        if (item.templateModule.id === value.templateModule.id) {
          return { ...item, templateModule: value.templateModule, defaults: value.defaults };
        }
        return item;
      });
    });
  }, []);

  const onNewDocumentClick = useCallback(() => {
    if (clientModuleTemplateDefaults.length === 0) {
      setActiveManageWizardStep(manageClassesWizardSteps[1]);
      setShowManageWizard(true);
    } else {
      setShowNewDocumentWizard(true);
      setActiveNewDocumentWizardStep(createDocumentWizardSteps[1]);
    }
  }, [clientModuleTemplateDefaults.length]);

  const onNewFromTemplateClick = useCallback(() => {
    if (clientModuleTemplateDefaults.length === 0) {
      setActiveManageWizardStep(manageClassesWizardSteps[1]);
      setShowManageWizard(true);
    } else {
      setShowNewDocumentWizard(true);
      setActiveNewDocumentWizardStep(createDocumentWizardSteps[4]);
    }
  }, [clientModuleTemplateDefaults.length]);

  const onCloseManageClassWizard = useCallback(() => {
    setShowManageWizard(false);
    setActiveManageWizardStep(undefined);
  }, []);

  const onShowManageClassWizard = useCallback(() => {
    setShowManageWizard(true);
    setActiveManageWizardStep(manageClassesWizardSteps[clientModuleTemplateDefaults.length === 0 ? 1 : 5]);
  }, [clientModuleTemplateDefaults.length]);

  const onCloseNewDocumentWizard = useCallback(() => {
    setShowNewDocumentWizard(false);
    setActiveNewDocumentWizardStep(undefined);
  }, []);

  const onDocumentCreated = useCallback(
    (id: string, classId: string, nextDocNumber: number) => {
      setClientModuleTemplateDefaults((prevDefaults) => {
        return prevDefaults.map((template) => {
          if (template.clientModuleId === classId) {
            return {
              ...template,
              nextDocumentNumber: nextDocNumber,
            };
          }
          return template;
        });
      });
      if (currentClient) {
        navigate(`/clients/${currentClient.id}/forms/${id}`);
      }
      onCloseNewDocumentWizard();
    },
    [currentClient, navigate, onCloseNewDocumentWizard],
  );

  const dataJobSource = useMemo(() => {
    if (!selectedClass) return;

    const templateDefault = clientModuleTemplateDefaults.find((x) => x.templateModule.id === selectedClass);
    return {
      id: templateDefault?.clientModuleId,
      name: templateDefault?.clientModuleName,
      type: DataJobSourceType.Module,
    } as DataJobSource;
  }, [clientModuleTemplateDefaults, selectedClass]);

  const getIndexes = useCallback(() => {
    if (!formsFilter || !currentClient) return;

    const scrollTop = indexesContentRef.current?.scrollTop || 0;
    setIndexesLoading(true);

    ModuleService.getIndexes(selectedClientModuleDefault?.clientModuleId || '', currentIndexPage, 15)
      .then((res) => {
        setIndexes((prev) => {
          if (prev?.data && currentIndexPage > 1) {
            return { ...res, data: [...prev.data, ...res.data] } as ApiResponse<IndexListItem[]> | null;
          }
          return { ...res } as ApiResponse<IndexListItem[]> | null;
        });

        nextTick(() => {
          indexesContentRef.current?.scrollTo({ top: scrollTop });
        });
      })
      .catch(() => {
        setIndexes({} as ApiResponse<IndexListItem[]>);
      })
      .finally(() => {
        setIndexesLoading(false);
      });
  }, [currentClient, currentIndexPage, formsFilter, selectedClientModuleDefault?.clientModuleId]);

  const onViewIndexClick = useCallback(() => {
    getIndexes();
    setShowIndexModal(true);
  }, [getIndexes]);

  const toasts = useToasts();
  const downloadIndex = useCallback(
    (id?: string) => {
      if (selectedClientModuleDefault) {
        toasts.addToast({ title: t('data-import-export:export.preparing'), type: ToastType.INFO, expiresInMs: 1000 });
        ModuleService.downloadIndexes(selectedClientModuleDefault?.clientModuleId, id)
          .then((res) => {
            const url = URL.createObjectURL(res);
            const link = document.createElement('a');
            link.download = `${t('documents:index-modal.title', {
              class: LanguageUtils.getTranslation('name', selectedClientModuleDefault?.templateModule.translations || {}),
            })} - ${DateUtils.formatDate(DateUtils.now)}.${id ? 'pdf' : 'zip'}`;
            link.href = url || '';
            link.click();
            setTimeout(() => {
              URL.revokeObjectURL(url);
            }, 100);
          })
          .catch(() => {
            toasts.addToast({ title: t('data-import-export:export.preparing-error'), type: ToastType.ERROR, expiresInMs: 1000 });
          });
      }
    },
    [selectedClientModuleDefault, t, toasts],
  );

  useEffect(function distributionCreated() {
    const handler = (event: DistributionResponse) => {
      setDocuments((prev) => {
        if (!prev || !prev.data) {
          return prev;
        }

        return {
          ...prev,
          data: prev.data.map((x) => {
            if (x.id === event.clientFormId) {
              return {
                ...x,
                distribution: event,
              };
            }
            return x;
          }),
        };
      });
    };

    EventSystem.listen('distribution-updated', handler);
    return () => {
      EventSystem.stopListening('distribution-updated', handler);
    };
  }, []);

  const onTabClick = useCallback(
    (classId: string) => {
      setSelectedClass(classId);
      navigate(`#${classId}`, { replace: true });
    },
    [navigate],
  );

  return (
    <div className="bg-background-1 flex min-h-full flex-col">
      <div className="h-0 flex-grow overflow-y-auto">
        <TopNavPortal>
          <Heading size={HeadingSize.H2} actualSize={HeadingSize.H3}>
            {t('documents:heading')}
          </Heading>
        </TopNavPortal>
        <div className="px-6 pb-6">
          <div className="-mx-6 mt-0 flex-grow">
            <ActivitiesContext.Provider value={{ hasContextMenu: true, type: 'documents' }}>
              <SelectItemContextProvider
                disable={!hasPermission(Roles.TeamMember)}
                space="document"
                downloadPreferences={selectedClientModuleDefault?.defaults?.configuration.downloadPdfPreferences}
              >
                <div className="flex min-h-full flex-col">
                  <div className="bg-background-1 sticky top-0 z-40 w-full">
                    <div className="relative my-6 mt-2 flex max-w-full flex-wrap items-center overflow-auto pl-4 pr-8">
                      <TabButton selected={!selectedClass} borderless onClick={() => onTabClick('')}>
                        {t('module:sections-all')}
                      </TabButton>

                      {clientModuleTemplateDefaults.map((x) => (
                        <TabButton
                          key={x.templateModule.id}
                          selected={selectedClass === x.templateModule.id}
                          borderless
                          onClick={() => onTabClick(x.templateModule.id)}
                        >
                          {LanguageUtils.getTranslation('name', x.templateModule.translations)}
                        </TabButton>
                      ))}
                      {hasPermission(Roles.TeamMember) && (
                        <CogIcon className="absolute right-4 top-4 h-5 w-5 cursor-pointer" onClick={onShowManageClassWizard} />
                      )}
                    </div>
                    <div className="flex items-center justify-between px-4 py-1">
                      <div className="flex flex-wrap items-center gap-2">
                        <FilterTag
                          tag={{
                            id: 'status',
                            text: t(`common:list.filter.status`),
                            value: 'status',
                          }}
                          mode={FilterSelectMode.Multi}
                          onFiltersChange={applyStatusFilter}
                          options={statusFilters}
                        />
                        {hasPermission(Roles.TeamMember) && (
                          <FilterTag
                            tag={{
                              id: 'user',
                              text: t(`common:list.filter.user`),
                              value: 'user',
                            }}
                            onFiltersChange={applyUsersFilter}
                            mode={FilterSelectMode.Multi}
                            options={clientUsersFilters}
                            onSearch={ownersFilterSearch}
                          />
                        )}
                        {(clientUsersFilters.filter((x) => x.value).length > 0 || !hasPermission(Roles.TeamMember)) && (
                          <FilterTag
                            tag={{
                              id: 'roles',
                              text: t(`common:list.filter.role`),
                              value: 'roles',
                            }}
                            onFiltersChange={setRoleFilters}
                            mode={FilterSelectMode.Multi}
                            options={roleFilters}
                          />
                        )}
                        {hasFiltersApplied && (
                          <>
                            <div className="border-gray-2 ml-4 mt-4 h-[25px] border-l" />
                            <Button type={ButtonType.TERTIARY} className="mt-4" onClick={clearAllFilters}>
                              <span className="border-primary-1 border-b-2">{t('common:list.filter.clear-all-filters')}</span>
                            </Button>
                          </>
                        )}
                      </div>
                      <div className="flex items-center justify-center gap-4">
                        <div className="w-128 flex gap-2">
                          <SearchInput
                            data-cy="activity-search"
                            placeholder={t('common:list.filter.search')}
                            value={activitySearchPhrase}
                            style={InputStyle.MINIMAL}
                            onChange={(e) => {
                              setCurrentPage(1);
                              setActivitySearchPhrase(e.target.value);
                            }}
                          />
                          <Button
                            type={ButtonType.SECONDARY}
                            size={ButtonSize.S}
                            onClick={onViewIndexClick}
                            disabled={!selectedClass.length}
                            title={!selectedClass.length ? t('documents:select-index-tooltip') : ''}
                          >
                            {t('documents:view-index')}
                          </Button>
                          <ContextMenu
                            items={[
                              {
                                title: t('documents:new.upload'),
                                onClick: onNewDocumentClick,
                              },
                              {
                                title: t('documents:new.template'),
                                onClick: onNewFromTemplateClick,
                              },
                            ]}
                            type="button"
                            size={ButtonSize.S}
                            selectedButtonTitle={t('documents:new.title')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex h-full w-full flex-grow flex-col" ref={pageContentRef}>
                    <div className="h-full px-4 pb-4">
                      <div className="bg-background-1 h-full py-4">
                        <SkeletonLoader type="listBlockRow" rows={5} size="medium" ready={!!documents}>
                          {documents && currentUser?.id && (
                            <DocumentList
                              formsFilter={formsFilter}
                              documentsPaged={documents}
                              onLoadMore={setCurrentPage}
                              onSort={onSortBy}
                              sortBy={sortBy}
                              isLoading={isActivitiesLoading}
                              onAddNewClick={onNewDocumentClick}
                              exportSource={dataJobSource}
                            />
                          )}
                        </SkeletonLoader>
                      </div>
                    </div>
                  </div>
                </div>
              </SelectItemContextProvider>
            </ActivitiesContext.Provider>
          </div>
        </div>
      </div>
      <ModalContext.Provider
        value={{
          open: showManageWizard,
          onClose: onCloseManageClassWizard,
          modalWidth: 'xl:w-[800px] w-3/5',
        }}
      >
        {activeManageWizardStep && (
          <ManageClassesWizard
            onCancel={onCloseManageClassWizard}
            classes={clientModuleTemplateDefaults}
            onCreated={onClassCreated}
            onUpdated={onClassUpdated}
            activeStep={activeManageWizardStep}
            clientUsers={users}
            onUserInvited={refetch}
          />
        )}
      </ModalContext.Provider>

      <ModalContext.Provider
        value={{
          open: showNewDocumentWizard,
          onClose: onCloseNewDocumentWizard,
          modalWidth: 'xl:w-[800px] w-3/5',
        }}
      >
        {activeNewDocumentWizardStep && (
          <CreateDocumentWizard
            key={selectedClientModuleDefault?.clientModuleId}
            classes={clientModuleTemplateDefaults}
            onCancel={onCloseNewDocumentWizard}
            onCreated={onDocumentCreated}
            classDefaults={selectedClientModuleDefault}
            activeStep={activeNewDocumentWizardStep}
            clientUsers={users}
            onUserInvited={refetch}
          />
        )}
      </ModalContext.Provider>
      <ModalContext.Provider
        value={{
          open: showIndexModal,
          onClose: () => setShowIndexModal(false),
          modalWidth: 'xl:w-[800px] w-3/5',
        }}
      >
        <StandardModal
          title={t('documents:index-modal.title', {
            class: LanguageUtils.getTranslation('name', selectedClientModuleDefault?.templateModule.translations || {}),
          })}
          onCancelClick={() => setShowIndexModal(false)}
          onConfirmClick={() => setShowIndexModal(false)}
          confirmButtonTitle={t('documents:index-modal.buttons.done')}
          onTertiaryButtonClick={(indexes?.totalCount || 0) > 0 ? () => downloadIndex() : undefined}
          tertiaryButtonTitle={t('documents:index-modal.buttons.download-all')}
          tertiaryButtonIcon={<DownloadIcon className="h-5 w-5" />}
        >
          <div ref={indexesContentRef}>
            <SkeletonLoader type="listBlockRow" rows={5} size="small" ready={!!indexes}>
              {indexes && selectedClientModuleDefault && currentUser?.id && (
                <IndexList indexesPaged={indexes} isLoading={indexesLoading} onLoadMore={setCurrentIndexPage} onDownloadIndex={downloadIndex} />
              )}
            </SkeletonLoader>
          </div>
        </StandardModal>
      </ModalContext.Provider>
    </div>
  );
};

export default Documents;
