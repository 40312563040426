import { FC } from 'react';
import { IndexListItem } from '../../models/Document';
import DownloadIcon from '../shared/icon/DownloadIcon';
import Tooltip from '../shared/Tooltip';
import { useTranslation } from 'react-i18next';
import DateUtils from '../../utils/DateUtils';
import { interpolate } from '../../utils/interpolation/InterpolationUtils';

const IndexRow: FC<{ indexItem: IndexListItem; onDownload: (id: string) => void }> = (props) => {
  const { indexItem, onDownload } = props;
  const { t } = useTranslation(['documents']);
  return (
    <div className="border-gray-3 border-t">
      <div className="flex gap-x-4 px-4 py-2 text-black">
        <div className="flex w-3/5 gap-x-4">
          <div className="w-1/6 font-medium">{`v${indexItem.version}`}</div>
          <div className="w-2/6">
            {indexItem.isLatest ? (
              <span className="text-semantic-1">{t('documents:index-modal.labels.master')}</span>
            ) : (
              <span className="text-gray-2">{t('documents:index-modal.labels.superseded')}</span>
            )}
          </div>
          <div className="w-3/6">{DateUtils.formatDateTime(new Date(indexItem.lastUpdatedOn))}</div>
        </div>
        <div className="w-2/5">{interpolate(indexItem.summaryOfChangesKey, indexItem.summaryOfChangesPlaceholders)}</div>
        <div className="w-5">
          <Tooltip text={t('documents:download-tooltip')}>
            {(tooltip) => (
              <div {...tooltip}>
                <DownloadIcon onClick={() => onDownload(indexItem.id)} className="h-5 w-5" />
              </div>
            )}
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default IndexRow;
