import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useActivites } from '../../contexts/ActivitiesContext';
import usePermissions from '../../hooks/permissions/usePermissions';
import { ClientFormStatus, FormStatusTag } from '../../models/ClientFormStatus';
import { ClientFormUser } from '../../models/ClientFormUser';
import { Roles } from '../../models/Role';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import ClientFormService from '../../services/ClientFormService';
import { dataAttributeProps, mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import FormUtils from '../../utils/FormUtils';
import { clientFormRoute, goToClientForm } from '../../utils/NavigationUtils';
import FormOwnership from '../ownership/FormOwnership';
import { OwnershipDisplayType } from '../ownership/OwnershipProps';
import DataRow from '../shared/data-grid/DataRow';
import Tooltip from '../shared/Tooltip';
import Checkbox from '../shared/form-control/Checkbox';
import FormInfoModal from '../form/FormInfoModal';
import DateUtils from '../../utils/DateUtils';
import { Access, AccessTypeKeys } from '../../models/Access';
import BuildingIcon from '../shared/icon/BuildingIcon';
import LockIcon from '../shared/icon/LockIcon';
import { DocumentListItem } from '../../models/Document';
import { useItemSelection } from '../../contexts/select-items/SelectItemsContext';
import DueDatePicker from '../shared/DueDatePicker';
import useFetchFormUsers from '../../hooks/useFetchFormUsers';
import DistributionWizard from '../distribution/DistributionWizard';
import StringUtils from '../../utils/StringUtils';
import DistributionStatusTag from '../distribution/DistributionStatusTag';
import DistributionManageModal from '../distribution/DistrubtionManageModal';
import { useDistributionPermissions } from '../../hooks/permissions/useDistributionPermissions';
import UncontrolledCopyModal from '../distribution/UncontrolledCopyModal';

type DocumentRowProps = {
  document: DocumentListItem;
  selected: boolean;
  onSelected: (value: boolean) => void;
  onDownload?: (activityId: string) => void;
  onArchive?: (activity: DocumentListItem) => void;
  onRestore?: (activityId: string) => void;
};

const DocumentRow: FC<DocumentRowProps> = (props) => {
  const { t } = useTranslation(['task-list', 'common', 'module', 'assets', 'documents', 'form']);
  const { document, onDownload, onArchive, onRestore, selected, onSelected } = props;
  const { hasContextMenu } = useActivites();
  const { selection } = useItemSelection();
  const currentClient = useRecoilValue(currentClientAtom);
  const currentUser = useRecoilValue(currentUserAtom);
  const isSharedReceiver = document.isShared && document?.clientId !== currentClient?.id;
  const [sortedUsers, setSortedUsers] = useState<ClientFormUser[]>([]);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showManageAccessModal, setShowManageAccessModal] = useState(false);
  const [showDistributionModal, setShowDistributionModal] = useState<'create' | 'manage' | false>(false);
  const [showUncontrolledCopyModal, setShowUncontrolledCopyModal] = useState(false);
  const navigate = useNavigate();
  const hasPermission = usePermissions();
  const [dueDateUtc, setDueDate] = useState<Date | null>(document.dueDateUtc ? new Date(document.dueDateUtc) : null);
  const { canCreateDistribution, canViewDistribution, canMaintainDistribution, canSendUncontrolledCopy } = useDistributionPermissions(
    document.distribution,
  );

  const onDueDateChange = (date: Date | null) => {
    setDueDate(date);
    ClientFormService.updateForm(
      document.id,
      document.accessType,
      date,
      document.effectiveDateUtc ? new Date(document.effectiveDateUtc) : null,
      document.subtitle,
      document.clientModuleId,
      document.clientModuleSectionId,
    );
  };

  const { data: users = [] } = useFetchFormUsers(document.id, document.users, document.isShared);

  const onUsersChange = (users: ClientFormUser[]) => {
    users.sort((a, b) => (a.firstName && b.firstName ? (a.firstName > b.firstName ? 1 : -1) : -1));
    setSortedUsers(users);
  };

  useEffect(() => {
    setSortedUsers(users);
  }, [users]);

  const activityTitle = useMemo(() => {
    return (
      <>
        {FormUtils.getDocumentTitle(document)}
        {FormUtils.formHostName(document, currentClient?.id || '', ' ({client})')}
        <span className="text-gray-2 text-dpm-14 ml-2">{`v${document.majorVersion}`}</span>
      </>
    );
  }, [document, currentClient?.id]);

  const isShared = useCallback(
    (formClientId: string) => {
      return formClientId !== currentClient?.id;
    },
    [currentClient?.id],
  );

  const onPreviewClick = (form: DocumentListItem): void => {
    goToClientForm(form, currentUser, navigate, true);
  };

  const updateDocumentAccess = useCallback(
    (value: Access) => {
      document.accessType = value;
      ClientFormService.updateForm(
        document.id,
        value,
        null,
        document.effectiveDateUtc ? new Date(document.effectiveDateUtc) : null,
        document.subtitle,
        document.clientModuleId,
        document.clientModuleSectionId,
      );
    },
    [document],
  );

  return (
    <DataRow
      {...dataAttributeProps(props)}
      url={clientFormRoute(document, currentUser)}
      contextMenuItems={[
        {
          title: t('assets:list.context-menu.details'),
          onClick: () => setShowDetailsModal(true),
          hide: !hasContextMenu,
        },
        {
          title: t('assets:list.context-menu.preview'),
          onClick: () => onPreviewClick(document),
          hide: !hasContextMenu || isShared(document.clientId),
        },
        {
          title: t('assets:list.context-menu.download'),
          onClick: () => {
            onDownload && onDownload(document.id);
          },
          hide: !hasContextMenu || (!onDownload && hasPermission(Roles.ExternalAuditor) && !hasPermission(Roles.Management)),
        },
        {
          title: t('assets:list.context-menu.distribute'),
          onClick: () => {
            setShowDistributionModal('create');
          },
          hide: !canCreateDistribution || !hasContextMenu || isShared(document.clientId),
        },
        {
          title: canMaintainDistribution ? t('assets:list.context-menu.manage-distribution') : t('assets:list.context-menu.view-distribution'),
          onClick: () => {
            setShowDistributionModal('manage');
          },
          hide: !canViewDistribution || !hasContextMenu || isShared(document.clientId),
        },
        {
          title: t('form:context.send-uncontrolled-copy'),
          onClick: () => setShowUncontrolledCopyModal(true),
          hide: !canSendUncontrolledCopy || !hasContextMenu || isShared(document.clientId),
        },
        {
          title: t('assets:list.context-menu.manage-access'),
          onClick: () => setShowManageAccessModal(true),
          hide:
            !hasContextMenu ||
            isShared(document.clientId) ||
            [ClientFormStatus.Completed, ClientFormStatus.SubmittedForApproval, ClientFormStatus.SubmittedForValidation].includes(document.status),
        },
        {
          title: t('common:list.context-menu.archive-documents'),
          onClick: () => {
            onArchive && onArchive(document);
          },
          hide: !hasContextMenu || !hasPermission(Roles.TeamMember) || isShared(document.clientId) || document.archivedUtc,
        },
        {
          title: t('module:list.context-menu.restore'),
          onClick: () => onRestore && onRestore(document.id),
          hide: !hasContextMenu || isSharedReceiver || !document.archivedUtc,
        },
      ]}
    >
      <div className="flex flex-grow items-center text-left">
        {!selection.isDisabled && (
          <div className="w-10">
            <Checkbox value={selected} onChange={onSelected} />
          </div>
        )}
        <div className="w-full pr-4">
          <Tooltip text={activityTitle} truncatedTextMode>
            {(tooltip) => (
              <div className="flex min-w-full max-w-0 items-center truncate font-medium text-black" {...tooltip}>
                <div className="truncate">{activityTitle} </div>
                {document.distribution && <DistributionStatusTag distribution={document.distribution} />}
              </div>
            )}
          </Tooltip>
        </div>
      </div>
      <div className="w-44 text-center">
        <Tooltip text={t(AccessTypeKeys[document.accessType], { client: currentClient?.name })}>
          {(tooltip) => (
            <div {...tooltip}>
              {document.accessType === Access.restricted && <LockIcon className="h-5 w-5 flex-shrink-0" />}
              {document.accessType === Access.intraClient && <BuildingIcon className="h-5 w-5 flex-shrink-0" />}
            </div>
          )}
        </Tooltip>
      </div>
      <div className="w-60" {...mouseAndKeyboardCallbackProps((e) => e.stopPropagation())}>
        <DueDatePicker
          clientFormStatus={document.status}
          value={dueDateUtc && new Date(dueDateUtc)}
          onChange={onDueDateChange}
          disabled={isSharedReceiver || (!hasPermission([Roles.TeamMember]) && !!currentUser)}
        />
      </div>
      <div className="w-52">{DateUtils.formatDateTime(new Date(document.modifiedUtc))}</div>
      <div className="w-40 pr-6">
        <FormOwnership
          users={sortedUsers}
          associatedId={document.id}
          size={OwnershipDisplayType.Tiny}
          onUsersChange={onUsersChange}
          open={showManageAccessModal}
          onClose={() => setShowManageAccessModal(false)}
          accessType={document.accessType}
          onAccesChange={updateDocumentAccess}
          requiresApproval={document.requiresApproval}
          requiresValidation={document.requiresValidation}
          requiresOwner
          displayLimit={3}
        />
      </div>
      <div className="w-52 text-center">
        <FormStatusTag activity={document} />
      </div>

      <FormInfoModal open={showDetailsModal} onClose={() => setShowDetailsModal(false)} form={document} clientFormUsers={sortedUsers} />

      {canCreateDistribution && (
        <DistributionWizard
          open={showDistributionModal === 'create'}
          onClose={() => setShowDistributionModal(false)}
          startingStep="start"
          clientFormId={document.id}
          clientFormVersion={document.majorVersion}
          clientFormSubtitle={
            StringUtils.makePrefixWithNumber(document.prefix, document.documentNumber, document.templateModuleTranslations) + '-' + document.subtitle
          }
        />
      )}

      {canViewDistribution && document.distribution && (
        <DistributionManageModal
          open={showDistributionModal === 'manage'}
          onClose={() => setShowDistributionModal(false)}
          distribution={document.distribution}
          clientFormSubtitle={
            StringUtils.makePrefixWithNumber(document.prefix, document.documentNumber, document.templateModuleTranslations) + '-' + document.subtitle
          }
        />
      )}
      {canSendUncontrolledCopy && (
        <UncontrolledCopyModal
          open={showUncontrolledCopyModal}
          onClose={() => setShowUncontrolledCopyModal(false)}
          clientFormId={document.id}
          clientFormVersion={document.majorVersion}
          clientFormSubtitle={
            StringUtils.makePrefixWithNumber(document.prefix, document.documentNumber, document.templateModuleTranslations) + '-' + document.subtitle
          }
        />
      )}
    </DataRow>
  );
};

export default DocumentRow;
