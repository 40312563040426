import { FC, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ApiResponse } from '../../models/ApiResponse';
import SortableHeading from '../shared/data-grid/SortableHeading';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import Loader from '../shared/Loader';
import DocumentRow from './DocumentRow';
import ClientFormService from '../../services/ClientFormService';
import { ModuleSection } from '../../models/Module';
import { useDownloadPdf } from '../../hooks/useDownloadPdf';
import FormUtils from '../../utils/FormUtils';
import { ModalContext } from '../../contexts/ModalContext';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';
import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';
import { DataJobSource, useItemSelection } from '../../contexts/select-items/SelectItemsContext';
import DocumentSpaceIcon from '../shared/icon/DocumentSpaceIcon';
import { DocumentListItem } from '../../models/Document';
import { ActivityFilter } from '../../models/ClientForm';

export type ModuleActivity = {
  section: ModuleSection;
  forms: DocumentListItem[];
};

type DocumentListProps = {
  documentsPaged: ApiResponse<DocumentListItem[]>;
  formsFilter?: ActivityFilter;
  onLoadMore?: (pageNumber: number) => void;
  sortBy: string;
  onSort?: (expression: string) => void;
  isLoading?: boolean;
  onAddNewClick: () => void;
  exportSource?: DataJobSource;
};

const DocumentList: FC<DocumentListProps> = (props) => {
  const { documentsPaged, formsFilter, onLoadMore, onSort, sortBy, isLoading = false, onAddNewClick, exportSource } = props;

  const [sortExpressions, setSortExpressions] = useState<Record<string, string>>({});

  const { t } = useTranslation(['documents', 'task-list', 'common', 'module', 'assets']);
  const currentPage = documentsPaged.pageNumber || 0;
  const totalPages = documentsPaged.totalPages || 0;
  const [showDocumentConfim, setShowDocumentConfim] = useState(false);
  const [documentToArchive, setDocumentToArchive] = useState<DocumentListItem | null>(null);
  const [excludeDocumentIds, setExcludeDocumentIds] = useState<string[]>([]);

  const updateSortExpression = (field: string, expression: string) => {
    const match = expression.match(/^([-+])/);
    let ascOrDesc = '-';
    if (match) {
      ascOrDesc = match[1];
    }
    const updatedSortExpressions: Record<string, string> = {};

    Object.keys(sortExpressions).forEach((key) => {
      updatedSortExpressions[key] = ascOrDesc + key;
    });

    setSortExpressions(updatedSortExpressions);

    const fullExpression = Object.values(updatedSortExpressions).filter((x) => x !== expression);
    fullExpression.unshift(expression); // move to first

    onSort && onSort(fullExpression.join(','));
  };

  useEffect(() => {
    let expressions = {};
    for (const expression of sortBy.split(',')) {
      expressions = { ...expressions, [expression.replace(/[-+]/g, '')]: expression };
    }
    setSortExpressions(expressions);
  }, [sortBy]);

  useEffect(() => {
    setExcludeDocumentIds([]);
  }, [documentsPaged]);

  const [lastElementRef] = useInfiniteScroll(currentPage < totalPages ? () => onLoadMore && onLoadMore(currentPage + 1) : null, isLoading);

  const archiveDocument = (activityId: string) => {
    ClientFormService.archiveForm(activityId).then(() => {
      setExcludeDocumentIds((prev) => [...prev, activityId]);
      setShowDocumentConfim(false);
    });
  };

  const restoreDocument = (activityId: string) => {
    ClientFormService.restoreForm(activityId).then(() => {
      setExcludeDocumentIds((prev) => [...prev, activityId]);
    });
  };

  const documents = useMemo(() => {
    return documentsPaged.data.filter((activity) => !excludeDocumentIds.includes(activity.id));
  }, [documentsPaged.data, excludeDocumentIds]);

  const { selection, mutateSelection, SelectAllButton, SelectionBarContent } = useItemSelection();

  useEffect(() => {
    formsFilter && mutateSelection.setFilter(formsFilter);
  }, [formsFilter, mutateSelection]);

  const selectText = useMemo(() => {
    if (selection.selectedAll || selection.selectedCount === documentsPaged.totalCount) {
      return exportSource ? 'documents:selection.all-class' : 'documents:selection.all-no-class';
    }

    if (selection.selectedCount === documentsPaged.data.length) {
      return exportSource ? 'documents:selection.all-visible-class' : 'documents:selection.all-visible-no-class';
    }

    return 'documents:selection.some';
  }, [selection.selectedAll, selection.selectedCount, documentsPaged.totalCount, documentsPaged.data.length, exportSource]);

  const { triggerPdfDownload, pdfDownloadModal } = useDownloadPdf('pdf');

  const selectionText = useMemo(
    () => (
      <Trans
        t={t}
        i18nKey={selectText}
        tOptions={{
          Count: selection.selectedCount,
          Total: (documentsPaged.totalCount ?? 0) - selection.excludedIds.length,
          Module: exportSource?.name,
        }}
        components={{
          Link: (
            <Button
              className="!p-0"
              type={ButtonType.TERTIARY}
              onClick={() =>
                selection.selectedAll || selection.selectedCount === documentsPaged.totalCount
                  ? mutateSelection.clear(documents)
                  : mutateSelection.selectAll(true)
              }
            />
          ),
        }}
      />
    ),
    [
      t,
      selectText,
      selection.selectedCount,
      selection.excludedIds.length,
      selection.selectedAll,
      documentsPaged.totalCount,
      exportSource,
      documents,
      mutateSelection,
    ],
  );
  return (
    <>
      <div className="bg-gray-5 rounded-[10px] px-2 py-3">
        <div className="flex w-full items-end border-2 border-transparent pl-4">
          <div className={`flex flex-grow text-left ${selection.active ? 'items-center' : 'items-end'}`}>
            {!selection.isDisabled && <div className="w-10">{SelectAllButton({ visibleItems: documents })}</div>}
            {selection.active ? (
              <div className="flex items-center gap-3">{SelectionBarContent({ source: exportSource, selectionText })}</div>
            ) : (
              <>
                <SortableHeading title={t('documents:list.name')} onSort={updateSortExpression} expression={sortExpressions['subtitle']} />
              </>
            )}
          </div>

          {!selection.active && (
            <>
              <div className="w-44 text-center">{t('documents:list.access')}</div>
              <div className="w-60">
                <SortableHeading title={t('task-list:list.heading.due')} onSort={updateSortExpression} expression={sortExpressions['dueDateUtc']} />
              </div>
              <div className="w-52">
                <SortableHeading title={t('documents:list.modified')} onSort={updateSortExpression} expression={sortExpressions['modifiedUtc']} />
              </div>
              <div className="w-40">{t('documents:list.members')}</div>
              <div className="w-52 pr-6 text-center">{t('documents:list.status')}</div>
              <div className="w-20">{/* SPACER */}</div>
            </>
          )}
        </div>

        {documents.map((documentItem, i) => {
          const isLast = documents.length === i + 1;
          return (
            <div key={documentItem.id} ref={isLast ? lastElementRef : undefined}>
              <DocumentRow
                data-cy={`document-list-item-${i}`}
                selected={selection.isSelected(documentItem)}
                onSelected={(value) => mutateSelection.selectIndividual(documentItem, value)}
                document={documentItem}
                onDownload={(id) => triggerPdfDownload(id, FormUtils.getDocumentTitle(documentItem))}
                onArchive={(document: DocumentListItem) => {
                  setDocumentToArchive(document);
                  setShowDocumentConfim(true);
                }}
                onRestore={restoreDocument}
              />
            </div>
          );
        })}
      </div>

      {isLoading && (
        <div className="flex flex-col items-center py-6">
          <Loader size={16} centered={false} />
        </div>
      )}

      {!isLoading && documentsPaged.data.length === 0 && (
        <div data-cy="documents-empty" className="mx-auto mt-24 flex w-1/2 flex-col items-center justify-center p-16 text-center">
          <DocumentSpaceIcon className="bg-primary-1 text-primary-1 h-16 w-16 rounded-full bg-opacity-10 p-4" />
          <div className="text-dpm-20 text-color-3 mt-8">{t(`documents:list.no-documents-filtered.heading`)}</div>
          <p className="text-color-3 pb-4">{t(`documents:list.no-documents-filtered.description`)}</p>
          <Button type={ButtonType.PRIMARY} onClick={onAddNewClick} size={ButtonSize.S}>
            {t(`documents:list.no-documents-filtered.button`)}
          </Button>
        </div>
      )}

      <ModalContext.Provider value={{ open: showDocumentConfim, onClose: () => setShowDocumentConfim(false), modalWidth: 'w-2/5' }}>
        <ConfirmationModal
          title={t('documents:archive-modal.heading')}
          description={documentToArchive?.isShared ? t('documents:archive-modal.body-shared') : t('documents:archive-modal.body')}
          confirmText={t('documents:archive-modal.buttons.archive')}
          cancelText={t('documents:archive-modal.buttons.cancel')}
          onConfirm={() => documentToArchive && archiveDocument(documentToArchive.id)}
          onCancel={() => setShowDocumentConfim(false)}
          alt
        />
      </ModalContext.Provider>

      {pdfDownloadModal}
    </>
  );
};

export default DocumentList;
